import React, {useCallback, useEffect, useState} from 'react';
import $ from "jquery";
import moment from "moment";
import {
    GET_COLLECTION_DATA,
    SEARCH_COLLECTION_URL
} from "../../../api";
import cogoToast from "cogo-toast";
import {getCollector, getPreSignedUrl, product, collector, territory, uploadPhoto, waste, zone} from "../../../api/app";
import {capitalize} from "../../../utils/capitalize";
import {DrawingManager, GoogleMap, useLoadScript} from "@react-google-maps/api";
import {PhotoProvider, PhotoView} from "react-photo-view";
const Buffer = require('buffer/').Buffer;

let coordinates = null;

// Google Map
const GMap = () => {
    const [center, setCenter] = useState({ lat: -15.4510304, lng: 28.4576441 });
    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        const zoomLevel = 10;
        const centerLatLng = new window.google.maps.LatLng(center);
        map.setCenter(centerLatLng);
        map.setZoom(zoomLevel);
        setMap(map);
    }, [])

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBcwPZxow61r9xp4GpD5CJDsJzz1WEXW8Y",
        libraries: ["drawing"]
    });

    const options = {
        drawingControl: true,
        drawingControlOptions: {
            drawingModes: ['polygon']
        },
        polygonOptions: {
            fillColor: '#FD2311',
            strokeColor: '#FD2311',
            fillOpacity: 0.5,
            strokeWeight: 3,
            clickable: true,
            editable: true, // Enable editing
            draggable: true,
            zIndex: 1
        }
    };

    const onPolygonComplete = useCallback(function onPolygonComplete(poly) {
        const polyArray = poly.getPath().getArray();
        let paths = [];
        polyArray.forEach((path) => {
            paths.push({lat: path.lat(), lng: path.lng()})
        });

        coordinates = paths;

        // console.log(paths);
    });

    return (
      <>
          {!isLoaded ? (
            <h6 style={{marginTop: 40}}>Loading Map...</h6>
          ) : (
            <GoogleMap
              mapContainerClassName="map-container"
              center={center}
              onLoad={onLoad}
              zoom={10}
            >
                <DrawingManager
                  options={options}
                  onPolygonComplete={onPolygonComplete}
                  onDragEnd={() => console.log("Dragged")}
                />
            </GoogleMap>
          )}
      </>
    );
};

const Data = (props) => {

    const token = localStorage.getItem('jwtToken');

    const id = props.match.params.id;
    const [loading, setLoading] = useState(true);
    const [garbageCollector, setGarbageCollector] = useState(null);
    const [stateReady, setStateReady] = useState(false);
    const [openMap, setOpenMap] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [logo_image, set_logo_image] = useState(null);
    const [editId, setEditId] = useState(null);
    const [isPhotoViewOpen, setPhotoViewOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [datatable] = useState({
        waste: false,
        dumpsite: false,
        territory: false,
        zone: false,
        product: false,
        driver: false,
        truck: false
    });

    useEffect(() => {
        getCollector(id).then((response) => {
            if(!response.error) {
                setGarbageCollector(response.payload);
                setLoading(false);
            }
        });
    }, []);

    const wasteDatatable = () => {
        if(datatable.waste) return false;
        datatable.waste = true;
        let table = $('#waste_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Waste Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "Waste",
                        fieldname: "name",
                        filter: {
                            collector: garbageCollector._id
                        },
                        pageSize: data.start,
                        format: 'json',
                        populate: "product",
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return capitalize(data.type);
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${garbageCollector.country.currency.symbol}${data.product.amount}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return capitalize(data.product.type);
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            "paid" : `<span class="badge badge-success tx-white">${capitalize(data.status)}</span>`,
                            "pending" : `<span class="badge badge-secondary tx-white">${capitalize(data.status)}</span>`,
                            "expired" : `<span class="badge badge-danger tx-white">${capitalize(data.status)}</span>`
                        }[data.status];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY")
                    },
                    "targets": 4
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_waste"><i class="fa fa-pencil"></i> View</a> <a href="#" class="nav-link delete_waste"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 5
                }
            ]
        });

        table.on('click', '.delete_waste', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, this data?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                waste("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        $('#waste_table').DataTable().ajax.reload();
                        cogoToast.success("Waste successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });
    }

    const zoneDatatable = () => {
        if(datatable.zone) return false;
        datatable.zone = true;
        let table = $('#zone_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Zone Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "Zone",
                        fieldname: "name",
                        filter: {
                            collector: garbageCollector._id
                        },
                        pageSize: data.start,
                        populate: [
                            {
                                path: 'products',
                                model: 'product',
                                populate: {
                                    path: 'property',
                                    model: 'property',
                                }
                            }
                        ],
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.name;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return {
                            "active" : `<span class="badge badge-success tx-white">${capitalize(data.status)}</span>`,
                            "inactive" : `<span class="badge badge-danger tx-white">${capitalize(data.status)}</span>`
                        }[data.status];
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY");
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        // return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_zone"><i class="fa fa-edit"></i> Edit</a> <a href="#" class="nav-link delete_zone"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link delete_zone"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.delete_zone', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, this data?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                zone("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        $('#zone_table').DataTable().ajax.reload();
                        cogoToast.success("Zone successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        table.on('click', '.edit_territory', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            const options = {
                position: "top-right",
                hideAfter: 0
            };

            let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

            let data = {
                id: extract_td.raw._id,
                status: extract_td.raw.status === "active" ? "inactive" : "active"
            }

            territory("put", data).then((result) => {
                hide();
                if(!result.error) {
                    $('#zone_table').DataTable().ajax.reload();
                    cogoToast.success(`Territory successfully ${extract_td.raw.status === "active" ? "deactivated" : "activated"}.`, {position: "top-right"});
                }
            });
        });

        table.on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function format(d) {
            // `d` is the original data object for the row
            return `<tr><td class="tx-13 pd-0">
                        <nav class="d-flex"><span class="text-danger tx-bold">Products:</span>
                            ${d.raw.products.map((item, index) => {
                return (`<tr><td class="tx-13 pd-0"><nav class="d-flex text-capitalize">${index+1}. ${item.type} (${item.property.name}) - K${item.amount}</nav></td></tr>`)
            })}`;
        }
    }

    const productDatatable = () => {
        if(datatable.product) return false;
        datatable.product = true;
        let table = $('#product_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Product Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "Product",
                        fieldname: "name",
                        filter: {
                            collector: garbageCollector._id
                        },
                        populate: {
                            path: 'property',
                            model: 'property',
                        },
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return capitalize(data.type);
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${garbageCollector.country.currency.symbol}${data.amount}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.period === 1 ? `${data.period} Day` : `${data.period} Days`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            "active" : `<span class="badge badge-success tx-white">${capitalize(data.status)}</span>`,
                            "depreciate" : `<span class="badge badge-danger tx-white">${capitalize(data.status)}</span>`
                        }[data.status];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return `<nav class="nav nav-icon-only">${data.status === "active" ? `<a href="#" class="nav-link edit_product"><i class="fa fa-pencil"></i> Edit</a>` : `<nav class="nav-link"><i class="fa fa-ban"></i> Closed</nav>`} <a href="#" class="nav-link delete_product"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.edit_product', function(e) {
            e.preventDefault();

            let amount = $("#amount");
            let description = $("#description");
            let type = $("#type");
            let property_select = $("#property-select2");

            let extract_td = table.row($(this).closest('tr')).data();

            setEditForm(true);
            setEditId(extract_td.raw._id);

            type.prop("disabled", true).prop("required", false);

            amount.val(extract_td.raw.amount);
            type.val(extract_td.raw.type);
            description.val(extract_td.raw.description);
            property_select.prop("disabled", true).prop("required", false);

            let $_option = $("<option selected></option>").val(extract_td.raw.property._id).text(capitalize(extract_td.raw.property.name));
            property_select.append($_option).trigger('change');

            initializePropertySearch();
            $('#product_form').parsley();

            $("#modalProduct").modal("show");
        });

        table.on('click', '.delete_product', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete product?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                product("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        $('#product_table').DataTable().ajax.reload();
                        cogoToast.success("Product successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });
    }

    const driverDatatable = () => {
        if(datatable.driver) return false;
        datatable.driver = true;
        $('#driver_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Driver Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "Driver",
                        fieldname: "name",
                        filter: {
                            collector: garbageCollector._id
                        },
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `${data.first_name} ${data.last_name}`;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.id_number;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.mobile_number;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            true : `<span class="badge badge-success tx-white">Active</span>`,
                            false : `<span class="badge badge-danger tx-white">Inactive</span>`
                        }[data.active];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY")
                    },
                    "targets": 4
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link approve_company"><i class="fa fa-eye"></i> View</a> <a href="#" class="nav-link edit_company"><i class="fa fa-pencil"></i> Edit</a></nav>`
                    },
                    "targets": 5
                }
            ]
        });
    }

    const truckDatatable = () => {
        if(datatable.truck) return false;
        datatable.truck = true;
        $('#truck_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Truck Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "Truck",
                        fieldname: "name",
                        filter: {
                            collector: garbageCollector._id
                        },
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.number_plate;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.make;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.model;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return data.year;
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only">No Action</nav>`
                    },
                    "targets": 4
                }
            ]
        });
    }

    const dumpsiteDatatable = () => {
        if(datatable.dumpsite) return false;
        datatable.dumpsite = true;
        let table = $('#dumpsite_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Dumpsite Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "Dumpsite",
                        fieldname: "name",
                        filter: {
                            collector: garbageCollector._id
                        },
                        populate: "collector staff",
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `${data.entry.quantity - data.exit.quantity} ${data.entry.unit}`;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${data.entry.quantity} ${data.entry.unit}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return `${data.exit.quantity} ${data.exit.unit}`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            "complete" : `<span class="badge badge-success tx-white">${capitalize(data.status)}</span>`,
                            "awaiting" : `<span class="badge badge-secondary tx-white">${capitalize(data.status)}</span>`
                        }[data.status];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY")
                    },
                    "targets": 4
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_photo"><i class="fa fa-eye"></i> View</a></nav>`
                    },
                    "targets": 5
                }
            ]
        });

        table.on('click', '.view_photo', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            setImages(extract_td.raw.entry.photos);
            $("#tx-status").text(extract_td.raw.status);
            $("#tx-entry").text(`${extract_td.raw.entry.quantity} ${extract_td.raw.entry.unit}`);
            $("#tx-exit").text(`${extract_td.raw.exit.quantity} ${extract_td.raw.exit.unit}`);
            $("#tx-weight").text(`${extract_td.raw.entry.quantity - extract_td.raw.exit.quantity} ${extract_td.raw.entry.unit}`);
            $("#tx-date").text(moment(extract_td.raw.date.iso).format("Do MMM, YYYY"));
            $("#tx-name").text(`${capitalize(extract_td.raw.collector.name)}`);
            $("#tx-staff").text(`${capitalize(extract_td.raw.staff.first_name)} ${capitalize(extract_td.raw.staff.last_name)}`);

            $("#modalPhoto").modal({ backdrop: 'static', focus: false, show: true });
        });
    }

    const handleZoneSubmit = (e) => {
        e.preventDefault();

        if(coordinates === null) return cogoToast.error("Please, draw a polygon shape on the map.", {position: "top-right", hideAfter: 5});

        setStateReady(true);

        let data = {
            name: $("#zone-name").val(),
            collector: garbageCollector._id,
            product: $("#product-select2").val(),
            geometry: coordinates
        };

        zone("post", data).then((result) => {
            setStateReady(false);

            if(!result.error) {
                cogoToast.success("Zone successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalZone", "zone_form");
                $('#zone_table').DataTable().ajax.reload();
            }
        });
    }

    // const handleZoneSubmit = (e) => {
    //     e.preventDefault();
    //
    //     setStateReady(true);
    //
    //     let data = {};
    //
    //     if(editForm) {
    //         data = {
    //             action: "update-zone",
    //             id: editId,
    //             meta: {
    //                 id: contentId,
    //                 name: $("#zone-name").val()
    //             }
    //         };
    //     } else {
    //         data = {
    //             action: "add-zone",
    //             id: contentId,
    //             zone: {
    //                 name: $("#zone-name").val()
    //             }
    //         };
    //     }
    //
    //     territory("put", data).then((result) => {
    //         setStateReady(false);
    //
    //         if(!result.error) {
    //             cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
    //             resetForm("modalZone", "zone_form");
    //             $('#zone_table').DataTable().ajax.reload();
    //         }
    //     });
    //
    // }

    const modalZoneForm = () => {
        setEditForm(false);
        productInitializeSearch();

        $('#zone_form').parsley();
        $("#modalZone").modal({ backdrop: 'static', focus: false, show: true });
        setOpenMap(true);
    }

    const productInitializeSearch = () => {
        let product_select = $('#product-select2');
        let modalZone = $('#modalZone');
        product_select.select2({
            placeholder: 'Select product or type name',
            allowClear: true,
            dropdownParent: modalZone,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization" : token,
                },
                data: function (query) {
                    return {
                        query: query,
                        collection: "products",
                        filter: {
                            collector: garbageCollector._id
                        },
                        populate: {
                            path: 'property',
                            model: 'property',
                        }
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data, function (item) {
                            return {
                                text: `${capitalize(item.type)} - ${item.property.name}`,
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        });
    }

    const handleModalProduct = () => {
        setEditForm(false);
        $('#type').prop("disabled", false).prop("required", true);
        $('#product_form').parsley();
        initializePropertySearch();
        $("#modalProduct").modal({ backdrop: 'static', focus: false, show: true });
    };

    const handleModalTruck = () => {
        setEditForm(false);
        $('#truck_form').parsley();
        $("#modalTruck").modal({ backdrop: 'static', focus: false, show: true });
    };

    const handleModalDumpsite = () => {
        setEditForm(false);
        $('#dumpsite_form').parsley();
        $("#modalDumpsite").modal({ backdrop: 'static', focus: false, show: true });
    };


    const modalCollectorForm = () => {
        setEditForm(true);
        let collector_select = $("#country-select2");
        collector_select.prop("disabled", true).prop("required", false);

        let $option = $("<option selected></option>").val(garbageCollector.country._id).text(garbageCollector.country.name);
        collector_select.append($option).trigger('change');

        $("#name").val(garbageCollector.name);
        $("#address").val(garbageCollector.address);
        $("#email").val(garbageCollector.email);
        $("#mobile").val(garbageCollector.mobile_number);
        $("#tax").val(garbageCollector.tax_id);

        $('#collector_form').parsley();
        $("#modalCollector").modal({ backdrop: 'static', focus: false, show: true });
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        setOpenMap(false);
        coordinates = null;
        $(".modal-backdrop").remove();
    };

    const handleFileUpload = (event) => {
        if(event.target.files[0] !== undefined && $('#logo_image').parsley().isValid() !== false) {
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                set_logo_image(reader.result);
            };
            reader.onerror = () => {
                set_logo_image(null);
            };
        }
    }

    const deleteDocument = (data, name) => {
        $(".data-message").text(`Are you sure, you want to ${name}?`);
        $('#delete-data').unbind("click").click(function(){

            $("#modalConfirm").modal("hide");
            $(".modal-backdrop").remove();

            const options = {
                position: "top-right",
                hideAfter: 0
            };

            let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

            territory("put", data).then((result) => {
                hide();
                if(!result.error) {
                    $('#zone_table').DataTable().ajax.reload();
                    cogoToast.success(`Document successfully removed.`, {position: "top-right"});
                }
            });
        });

        $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
    }

    const handleCollectorSubmit = async(e) => {
        e.preventDefault();
        setStateReady(true);

        let data = {
            id: garbageCollector._id,
            name : $("#name").val(),
            address: $("#address").val(),
            logo_uri : logo_image !== null ? await submitPhoto() : null,
            email : $("#email").val(),
            mobile_number : $("#mobile").val(),
            tax_id : $("#tax").val(),
            password: $("#password").val()
        }

        collector("put", data).then((result) => {
            setStateReady(false);
            if(!result.error) {
                cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                resetForm("modalCollector", "collector_form");
                setGarbageCollector(result.payload);
            }
        })

        return false;
    }

    const handleProductSubmit = async(e) => {
        e.preventDefault();

        let amount = $('#amount').val();
        let description = $('#description').val();

        setStateReady(true);

        if(editForm) {
            let data = {
                id: editId,
                amount,
                description
            };

            product("put", data).then((result) => {
                setStateReady(false);

                if(!result.error) {
                    cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                    resetForm("modalProduct", "product_form");
                    $('#product_table').DataTable().ajax.reload()
                }
            });

            return false;
        }

        let data = {
            amount: amount,
            description: description,
            type: $('#type').val(),
            property: $('#property-select2').val()[0],
            collector: garbageCollector._id
        };

        product("post", data).then((result) => {
            setStateReady(false);
            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalProduct", "product_form");
                $('#product_table').DataTable().ajax.reload()
            }
        });
    }

    const initializePropertySearch = () => {
        let modalProduct = $('#modalProduct');
        $('#property-select2').select2({
            placeholder: 'Select property type or type name',
            allowClear: true,
            width:"100%",
            maximumSelectionLength: 1,
            dropdownParent: modalProduct,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization": token,
                },
                data: function(query) {
                    return {
                        query: query,
                        collection: "property"
                    };
                },
                processResults: function(data) {
                    return {
                        results: $.map(data, function(item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        });
    }

    const submitPhoto = () => {
        return getPreSignedUrl().then(async (response) => {
            if (response.error) {
                return false;
            }

            const config = {
                headers: {
                    'Content-Type': 'image/jpeg; charset=utf-8',
                    'x-amz-acl': 'public-read'
                }
            };

            const buffer = new Buffer.from(logo_image.replace(/^data:image\/\w+;base64,/, ""), 'base64')

            return uploadPhoto(response.payload.url, buffer, config).then((result) => {
                if (!result.error) {
                    return result.payload.config.url.replace(/\?.*$/g,"");
                }

                return null;
            });
        })
    };

    if(!loading) {
        return (
            <div className="content-body">
                <div className="container-fluid pd-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                    <li className="breadcrumb-item"><a href="#">Collector</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Details</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="d-none d-md-block">
                            {garbageCollector.availability ?
                                <span className="badge badge-success tx-white">Available</span> :
                                <span className="badge badge-danger tx-white">Unavailable</span>}
                        </div>
                    </div>

                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div className="media">
                            <div
                                className="wd-100 ht-100 bg-ui-04 rounded d-flex align-items-center justify-content-center bg-white">
                                <img src={garbageCollector.logo_uri} className="img-fluid rounded-5" alt="logo"/>
                            </div>
                            <div className="media-body pd-l-25 my-auto">
                                <h5 className="mg-b-5">{capitalize(garbageCollector.name)}</h5>
                                <p className="mg-b-3 text-capitalize">
                                    <span className="tx-medium tx-color-02">{garbageCollector.tax_id}</span><br/>
                                    {garbageCollector.address}, {garbageCollector.country.name}
                                </p>
                                <span className="d-block tx-13 tx-color-03">{garbageCollector.email}</span>
                            </div>
                        </div>
                        <div className="d-none d-md-block">
                            <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none"
                                    onClick={modalCollectorForm.bind()}>
                                <i className="wd-10 mg-r-5 fa fa-truck"></i> Edit Profile
                            </button>
                        </div>
                    </div>

                    <div className="row row-xs">
                        <div className="col-md-12 mg-t-10">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <div>
                                    <ul id="nav_basic" className="nav nav-line nav-fill tx-medium">
                                        <li className="nav-item"><a href="#basic " className="nav-link active"
                                                                    data-toggle="tab"><i
                                            className="ion-md-information-circle"/> Collector</a></li>
                                        <li className="nav-item"><a href="#waste" className="nav-link" data-toggle="tab"
                                                                    onClick={wasteDatatable.bind()}><i
                                            className="ion-md-trash"/> Waste</a></li>
                                        <li className="nav-item"><a href="#dumpsite" className="nav-link"
                                                                    data-toggle="tab"
                                                                    onClick={dumpsiteDatatable.bind()}><i
                                            className="ion-md-trash"/> Dumpsite</a></li>
                                        <li className="nav-item"><a href="#zone" className="nav-link" data-toggle="tab"
                                                                    onClick={zoneDatatable.bind()}><i
                                            className="ion-ios-map"/> Zone</a></li>
                                        <li className="nav-item"><a href="#product" className="nav-link"
                                                                    data-toggle="tab" onClick={productDatatable.bind()}><i
                                            className="ion-ios-document"/> Product</a></li>
                                        <li className="nav-item"><a href="#driver" className="nav-link"
                                                                    data-toggle="tab"
                                                                    onClick={driverDatatable.bind()}><i
                                            className="ion-ios-car"/> Drivers</a></li>
                                        <li className="nav-item"><a href="#truck" className="nav-link" data-toggle="tab"
                                                                    onClick={truckDatatable.bind()}><i
                                            className="ion-ios-car"/> Trucks</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div id="basic" className="tab-pane fade active show">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <p><strong>Mobile #:</strong> {garbageCollector.mobile_number}</p>
                                            <p><strong>Grant Access:</strong> {garbageCollector.grant_access ?
                                                <span className="badge badge-success tx-white">Enabled</span> :
                                                <span className="badge badge-danger tx-white">Disabled</span>}</p>
                                            <p><strong>Country:</strong> {capitalize(garbageCollector.country.name)}</p>
                                            <p><strong>Last
                                                Login:</strong> {moment(garbageCollector.last_login.str).format("Do MMM, YYYY")}
                                            </p>
                                            <p><strong>Date
                                                Created:</strong> {moment(garbageCollector.date.str).format("Do MMM, YYYY")}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div id="waste" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div
                                                    className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Wastes</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={() => $('#waste_table').DataTable().ajax.reload()}><i
                                                            className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="waste_table" className="table table-hover"
                                                           style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Type</th>
                                                            <th className="wd-20p">Amount</th>
                                                            <th className="wd-15p">Product</th>
                                                            <th className="wd-10p">Status</th>
                                                            <th className="wd-15p">Date Issued</th>
                                                            <th className="wd-15p">Actions</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="dumpsite" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div
                                                    className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Dumpsite</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={() => $('#dumpsite_table').DataTable().ajax.reload()}><i
                                                            className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="dumpsite_table" className="table table-hover"
                                                           style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Total Weight</th>
                                                            <th className="wd-20p">Entry Data</th>
                                                            <th className="wd-15p">Exit Data</th>
                                                            <th className="wd-15p">Status</th>
                                                            <th className="wd-10p">Date</th>
                                                            <th className="wd-15p">Action</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="zone" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div
                                                    className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Zones</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={() => $('#zone_table').DataTable().ajax.reload()}><i
                                                            className="icon ion-md-refresh"></i></a>
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={modalZoneForm.bind()}><i
                                                            className="icon ion-md-add"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="zone_table" className="table" style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th className="wd-25p">Name</th>
                                                            <th className="wd-25p">Status</th>
                                                            <th className="wd-25p">Date Created</th>
                                                            <th className="wd-25p">Action</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="product" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div
                                                    className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Products</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={() => $('#product_table').DataTable().ajax.reload()}><i
                                                            className="icon ion-md-refresh"></i></a>
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={handleModalProduct.bind()}><i
                                                            className="icon ion-md-add"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="product_table" className="table table-hover"
                                                           style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Type</th>
                                                            <th className="wd-15p">Amount</th>
                                                            <th className="wd-15p">Period</th>
                                                            <th className="wd-10p">Status</th>
                                                            <th className="wd-15p">Action</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="driver" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div
                                                    className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Driver</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={() => $('#driver_table').DataTable().ajax.reload()}><i
                                                            className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="driver_table" className="table table-hover"
                                                           style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Name</th>
                                                            <th className="wd-15p">ID Number</th>
                                                            <th className="wd-15p">Mobile Number</th>
                                                            <th className="wd-10p">Job Status</th>
                                                            <th className="wd-10p">Date</th>
                                                            <th className="wd-15p">Actions</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="truck" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div
                                                    className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Trucks</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={() => $('#truck_table').DataTable().ajax.reload()}><i
                                                            className="icon ion-md-refresh"></i></a>
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={handleModalTruck.bind()}><i
                                                            className="icon ion-md-add"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="truck_table" className="table table-hover"
                                                           style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Number Plate</th>
                                                            <th className="wd-15p">Make</th>
                                                            <th className="wd-15p">Model</th>
                                                            <th className="wd-10p">Year</th>
                                                            <th className="wd-15p">Action</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalZone" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15"
                                       data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="#" role="button"
                                                                   className="close pos-absolute t-15 r-15"
                                                                   data-dismiss="modal"
                                                                   onClick={resetForm.bind(null, "modalZone", "zone_form")}
                                                                   aria-label="Close"><span aria-hidden="true">×</span></a>
                                        <h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Zone" : "Create Zone"}</h4>
                                        <p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} zone
                                            information.</p>
                                    </div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="zone_form" className="parsley-style-1" onSubmit={handleZoneSubmit.bind()}>
                                        <div className="row row-sm mg-b-10">
                                            <div id="zoneNameWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Name<span className="tx-danger">*</span></label>
                                                <input id="zone-name" type="text" className="form-control"
                                                       placeholder="Enter zone name"
                                                       autoComplete="none"
                                                       data-parsley-class-handler="#zoneNameWrapper"
                                                       required
                                                />
                                            </div>

                                            <div id="productWrapper" className="form-group parsley-input col-sm-12">
                                                <div className="d-flex justify-content-between">
                                                    <label>Products<span className="tx-danger">*</span></label>
                                                </div>
                                                <select className="form-control" id="product-select2" data-width="100%"
                                                        multiple="multiple"
                                                        data-parsley-class-handler="#productWrapper"
                                                        data-parsley-errors-container="#productWrapper"
                                                        autoComplete="off"
                                                        required>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="mg-b-20">
                                            {openMap ? <GMap/> : null}
                                        </div>

                                        {stateReady ? <button disabled className="btn btn-success btn-block"><span
                                                className="spinner-border spinner-border-sm mg-r-10" role="status"
                                                aria-hidden="true"/>Please Wait</button> :
                                            <button className="btn btn-success btn-block">Add Zone</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog"
                         aria-labelledby="confirmation" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content tx-14">
                                <div className="modal-header">
                                    <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="mg-b-0 data-message">Empty</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" id="delete-data" className="btn btn-success btn-block">Yes,
                                        Delete
                                    </button>
                                    <button type="button" className="btn btn-danger btn-block mt-0"
                                            data-dismiss="modal">Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalCollector" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15"
                                       data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="" role="button"
                                                                   className="close pos-absolute t-15 r-15"
                                                                   data-dismiss="modal"
                                                                   onClick={resetForm.bind(null, "modalCollector", "collector_form")}
                                                                   aria-label="Close"><span aria-hidden="true">×</span></a>
                                        <h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Garbage Collector" : "New Garbage Collector"}</h4>
                                        <p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} garbage
                                            collector information.</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="collector_form" className="parsley-style-1"
                                          onSubmit={handleCollectorSubmit.bind()}>
                                        <div id="nameWrapper" className="form-group parsley-input">
                                            <label>Company Name<span className="tx-danger">*</span></label>
                                            <input id="name" type="text" className="form-control"
                                                   placeholder="Enter company name"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#nameWrapper" required/>
                                        </div>

                                        <div id="logoWrapper" className="form-group parsley-input">
                                            <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Company
                                                Logo</label>
                                            <input id="logo_image" type="file" className="form-control"
                                                   onChange={handleFileUpload.bind()}
                                                   data-parsley-filemaxmegabytes="1"
                                                   data-parsley-trigger="change"
                                                   data-parsley-filemimetypes="image/jpeg, image/jpg, image/png"
                                                   accept="image/jpeg, image/jpg, image/png"
                                                   data-parsley-class-handler="#logoWrapper"
                                            />
                                        </div>

                                        <div id="emailWrapper" className="form-group parsley-input">
                                            <label>Email<span className="tx-danger">*</span></label>
                                            <input id="email" type="email" className="form-control"
                                                   placeholder="Enter email address"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#emailWrapper" required/>
                                        </div>

                                        <div id="mobileWrapper" className="form-group parsley-input">
                                            <label>Mobile Number<span className="tx-danger">*</span></label>
                                            <input id="mobile" type="number" className="form-control"
                                                   placeholder="Enter mobile number"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#mobileWrapper" required/>
                                        </div>

                                        <div id="addressWrapper" className="form-group parsley-input">
                                            <label>Physical Address<span className="tx-danger">*</span></label>
                                            <input id="address" type="text" className="form-control"
                                                   placeholder="Enter physical address"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#addressWrapper" required/>
                                        </div>

                                        <div id="taxWrapper" className="form-group parsley-input">
                                            <label>Tax ID<span className="tx-danger">*</span></label>
                                            <input id="tax" type="text" className="form-control"
                                                   placeholder="Enter tax ID or TPIN #"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#taxWrapper" required/>
                                        </div>

                                        <div id="passwordWrapper" className="form-group parsley-input">
                                            <label>Password<span className="tx-danger">*</span></label>
                                            <input id="password" type="text" className="form-control"
                                                   placeholder="Enter new password"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#passwordWrapper"/>
                                        </div>

                                        {stateReady ? <button disabled className="btn btn-success btn-block"><span
                                            className="spinner-border spinner-border-sm mg-r-10" role="status"
                                            aria-hidden="true"/>Please Wait</button> : editForm ?
                                            <button className="btn btn-success btn-block">Update Collector</button> :
                                            <button className="btn btn-success btn-block">Create Collector</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalProduct" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15"
                                       data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="#" role="button"
                                                                   className="close pos-absolute t-15 r-15"
                                                                   data-dismiss="modal"
                                                                   onClick={resetForm.bind(null, "modalProduct", "product_form")}
                                                                   aria-label="Close"><span aria-hidden="true">×</span></a>
                                        <h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Product" : "Create Product"}</h4>
                                        <p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} product
                                            information.</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="product_form" className="parsley-style-1"
                                          onSubmit={handleProductSubmit.bind()}>
                                        <div id="propertyWrapper" className="form-group parsley-input">
                                            <div className="d-flex justify-content-between">
                                                <label>Property<span className="tx-danger">*</span></label>
                                            </div>
                                            <select className="form-control" id="property-select2" data-width="100%"
                                                    multiple="multiple"
                                                    data-parsley-class-handler="#propertyWrapper"
                                                    data-parsley-errors-container="#propertyWrapper"
                                                    autoComplete="off"
                                                    required>
                                            </select>
                                        </div>

                                        <div id="typeWrapper" className="form-group parsley-input">
                                            <label>Type<span className="tx-danger">*</span></label>
                                            <select className="custom-select" id="type" required>
                                                <option value="" disabled selected>Select</option>
                                                <option value="subscription">Subscription</option>
                                                <option value="pickup">Special Pickup</option>
                                            </select>
                                        </div>

                                        <div id="amountWrapper" className="form-group parsley-input">
                                            <label>Amount<span className="tx-danger">*</span></label>
                                            <input id="amount" type="number" className="form-control"
                                                   placeholder="Enter amount"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#amountWrapper" required/>
                                        </div>

                                        <div id="descriptionWrapper" className="form-group parsley-input">
                                            <label
                                                className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Description</label>
                                            <textarea id="description" rows="5" className="form-control"
                                                      placeholder="Provide a description"
                                                      data-parsley-class-handler="#descriptionWrapper"
                                                      required></textarea>
                                        </div>

                                        {stateReady ? <button disabled className="btn btn-success btn-block"><span
                                            className="spinner-border spinner-border-sm mg-r-10" role="status"
                                            aria-hidden="true"/>Please Wait</button> : editForm ?
                                            <button className="btn btn-success btn-block">Update Product</button> :
                                            <button className="btn btn-success btn-block">Create Product</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalPhoto" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15"
                                       data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close" onClick={() => setImages([])}><span
                                        aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Dumpsite Report</h4>
                                        <p className="tx-13 tx-color-02 mg-b-0">Reported By: <span
                                            id="tx-staff">---</span></p></div>
                                </div>
                                <div className="modal-body pd-sm-b-40 pd-sm-x-30">
                                    <p><strong>Garbage Collector:</strong> <span className="text-capitalize" id="tx-name">---</span></p>
                                    <p><strong>Entry Data:</strong> <span className="text-capitalize" id="tx-entry">---</span></p>
                                    <p><strong>Exit Data:</strong> <span className="text-capitalize" id="tx-exit">---</span></p>
                                    <p><strong>Weight Data:</strong> <span className="text-capitalize" id="tx-weight">---</span></p>
                                    <p><strong>Status:</strong> <span className="text-capitalize" id="tx-status">---</span></p>
                                    <p><strong>Report Date:</strong> <span className="text-capitalize" id="tx-date">---</span></p>
                                    {images.length > 0 ? <PhotoProvider>
                                        <p className="tx-primary">Click Photos Below</p>
                                        <div className="img-group float-left">
                                            {images.map((item, index) => (
                                                <PhotoView key={index} src={item}>
                                                    <img src={item} className="img wd-60 ht-60 rounded-circle" alt=""/>
                                                </PhotoView>
                                            ))}
                                        </div>
                                    </PhotoProvider> : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    {isPhotoViewOpen && (
                        <PhotoProvider>
                            {images.map((item, index) => (
                                <PhotoView key={index} src={item}>
                                    <img src={item} className="img wd-60 ht-60 rounded-circle" alt=""/>
                                </PhotoView>
                            ))}
                        </PhotoProvider>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className="content-body">
            <div className="container d-flex justify-content-center ht-100p">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p className="mg-t-10">Collecting Data</p>
                </div>
            </div>
        </div>
    )
};

export default Data;
