import React from 'react';
import PrivateRoute from 'components/common/PrivateRoute';
import {Switch, Route} from 'react-router-dom';
import PageError from "components/layout/PageError";
import AccessControl from "pages/authorized/settings/AccessControl";
import User from "pages/authorized/user/index";
import Region from "../../pages/authorized/settings/Region";
import Product from "../../pages/authorized/settings/Product";
import Collector from "../../pages/authorized/collector/index";
import Transaction from "../../pages/authorized/transaction/index";
import Report from "../../pages/authorized/report/index";
import CollectorData from "../../pages/authorized/collector/Data";
import UserData from "../../pages/authorized/user/Data";
import PushNotification from "../../pages/authorized/settings/PushNotification";
import Waste from "../../pages/authorized/general/Waste";
import Collection from "../../pages/authorized/general/Collection";
import Media from "../../pages/authorized/other/Media";
import Finance from "../../pages/authorized/finance";

const Private = () => {

    return (
        <Switch>
            <PrivateRoute exact path="/user" component={User} />
            <PrivateRoute exact path="/waste" component={Waste} />
            <PrivateRoute exact path="/collection" component={Collection} />
            <PrivateRoute exact path="/error" component={PageError} />
            <PrivateRoute exact path="/region" component={Region} />
            <PrivateRoute exact path="/product" component={Product} />
            <PrivateRoute exact path="/collector" component={Collector} />
            <PrivateRoute exact path="/finance" component={Finance} />
            <PrivateRoute exact path="/zone" component={Region} />
            <PrivateRoute exact path="/report" component={Report} />
            <PrivateRoute exact path="/transaction" component={Transaction} />
            <PrivateRoute exact path="/collector/:id" component={CollectorData} />
            <PrivateRoute exact path="/user/:id" component={UserData} />
            <PrivateRoute exact path="/notification" component={PushNotification} />
            <PrivateRoute exact path="/access_control" component={AccessControl} />
            <PrivateRoute exact path="/media" component={Media} />
            <Route component={PageError} />
        </Switch>
    )
};

export default Private;
